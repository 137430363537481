
/* ==========================================================================
   $CLASSIC-TIME-PICKER
   ========================================================================== */

@import "_variables.less";


/**
 * Note: the root picker element should __NOT__ be styled
 * more than what’s here. Style the `.picker__holder` instead.
 */
.picker--time {

    // Adjust the min & max widths.
    min-width: @time-min-width;
    max-width: @time-max-width;
}


/**
 * The holder is the base of the picker.
 */
.picker--time .picker__holder {

    // Add a slight background color.
    background: @bg-grey-light;

    // For `medium` screens, reduce the font-size a bit to get more in view.
    @media ( min-height: @breakpoint-medium ) {
        font-size: .875em;
    }
}


/**
 * The box contains the list of times.
 */
.picker--time .picker__box {

    // Remove any stylings overflowing from the date picker.
    padding: 0;

    // Make the “viewset” time position relative to the box.
    position: relative;
}



